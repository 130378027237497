import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';

const stateSelector = (state: GlobalState) => state;
const shouldLoginSlice = createSelector(stateSelector, (state) => state.shouldLogin);

export const isTracking = createSelector(shouldLoginSlice, (slice) => slice.startTracking);

export const isCompleteAccountModalOpen = createSelector(shouldLoginSlice, (slice) => slice.completeAccountModalOpen);

export const getTriggerId = createSelector(shouldLoginSlice, (slice) => slice.trigger || null);
