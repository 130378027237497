import { KameleoonGoalData } from '@/hooks/kameleoon/kameleoonGoals';
import { useTrackConversion } from '@/hooks/kameleoon/useTrackConversion';

export const useTrackFollowedPriceResultsSearch = () =>
    useTrackConversion({ goal: KameleoonGoalData.FollowedPriceResultsSearch });

export const useTrackSavedPriceResultsItem = () =>
    useTrackConversion({ goal: KameleoonGoalData.SavedPriceResultsItem });

export const useTrackItemCardClickedPriceResults = () =>
    useTrackConversion({ goal: KameleoonGoalData.ItemCardClickedPriceResults });

export const useTrackMainItemClickedPriceResults = () =>
    useTrackConversion({ goal: KameleoonGoalData.MainItemClickedPriceResults });
