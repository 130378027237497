import * as React from 'react';
import { AddressModalData } from '@/types/address/address.types';
import {
    getDefaultAddressIsVerified,
    isProfileComplete,
    isUserAtLeastPartiallyRegistered,
    isUserLoggedIn,
} from '@/redux/modules/account/user/user.selectors';
import {
    getTriggerId,
    isCompleteAccountModalOpen,
    isTracking,
} from '@/redux/modules/account/shouldLogin/shouldLogin.selectors';
import { getUiUpdateAccount } from '@/redux/modules/account/updateAccount/updateAccount';
import { ModalContext } from '@/redux/modules/modal';
import {
    openCompleteAccountSignupModalFromChecker,
    stopTracker,
} from '@/redux/modules/account/shouldLogin/shouldLogin.actions';
import { SearchParams as SearchParamsType } from '@/types/SaveSearch';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useCallback, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid';
import isEqual from 'react-fast-compare';

export type ShouldLogInContainerProps<RunFunctionArgumentType = void> = {
    allSearchParams?: SearchParamsType;
    children: React.ReactNode;
    completeAddressData?: AddressModalData;
    forceConfirmPassword?: boolean;
    forceSignUp?: boolean;
    modalContext?: ModalContext;
    requireCompleteProfile?: boolean;
    requireLogin?: boolean;
    requireUserAtLeastPartiallyRegistered?: boolean;
    requireVerifiedAddress?: boolean;
    runFunction?: (arg: RunFunctionArgumentType) => void;
    runFunctionArgument?: RunFunctionArgumentType | undefined;
};

// prettier-ignore
const ShouldLogInContainer = <RunFunctionArgumentType,>({
    children,
    forceSignUp = true,
    modalContext,
    requireCompleteProfile = false,
    requireLogin = true,
    requireUserAtLeastPartiallyRegistered,
    requireVerifiedAddress = false,
    runFunction,
    runFunctionArgument,
}: ShouldLogInContainerProps<RunFunctionArgumentType>) => {
    const guid = React.useMemo(() => uuidv4(), []);
    const dispatch = useAppDispatch();

    const userLoggedIn = useAppSelector(isUserLoggedIn);
    const isCompleteProfile = useAppSelector(isProfileComplete);
    const triggerId = useAppSelector(getTriggerId, isEqual);
    const tracking = useAppSelector(isTracking, isEqual);
    const completeAccountModalOpen = useAppSelector(isCompleteAccountModalOpen);
    const userAtLeastPartiallyRegistered = useAppSelector(isUserAtLeastPartiallyRegistered);
    const uiUpdateAccount = useAppSelector(getUiUpdateAccount);

    const isDefaultAddressVerified = useAppSelector(getDefaultAddressIsVerified);

    // ensure that the same function isnt run twice in quick succession
    const callback = useDebouncedCallback(runFunction || (() => {}), 50);
    const contextCallback = useDebouncedCallback(modalContext?.runAtLoginFunction || (() => {}), 50);

    // Determines page-level container behavior on change, either running passed in function,
    // or running component logic if we're not confirming password
    useEffect(() => {
        if (guid === triggerId && tracking) {
            if (
                !completeAccountModalOpen &&
                userAtLeastPartiallyRegistered &&
                (userLoggedIn || !requireLogin) &&
                (isCompleteProfile || !requireCompleteProfile) &&
                Boolean(runFunction)
            ) {
                dispatch(stopTracker());
                callback(runFunctionArgument);
            }

            if (
                userLoggedIn &&
                requireCompleteProfile &&
                !isCompleteProfile &&
                !completeAccountModalOpen &&
                !uiUpdateAccount.submitted &&
                !uiUpdateAccount.success
            ) {
                dispatch(openCompleteAccountSignupModalFromChecker({ modalContext, trigger: guid }));
            }
        }
    }, [
        callback,
        completeAccountModalOpen,
        dispatch,
        guid,
        isCompleteProfile,
        requireCompleteProfile,
        requireLogin,
        modalContext,
        runFunction,
        runFunctionArgument,
        tracking,
        triggerId,
        uiUpdateAccount.submitted,
        uiUpdateAccount.success,
        userAtLeastPartiallyRegistered,
        userLoggedIn,
    ]);

    const handleClick = useCallback(
        async (event: MouseEvent) => {
            event.stopPropagation();
            if (
                (forceSignUp && !userLoggedIn && !userAtLeastPartiallyRegistered) ||
                (forceSignUp && !userLoggedIn && requireLogin) ||
                (!userAtLeastPartiallyRegistered && requireUserAtLeastPartiallyRegistered) ||
                (!isCompleteProfile && requireCompleteProfile) ||
                (isCompleteProfile && requireVerifiedAddress && !isDefaultAddressVerified)
            ) {
                // populate modalContext with runAtLoginFunction if using ShouldLoginContainer
                // from pop-up menu or any disappearing element to persist action
                dispatch(openCompleteAccountSignupModalFromChecker({ modalContext, trigger: guid }));
            } else if (Boolean(runFunction)) {
                callback(runFunctionArgument);
            } else if (Boolean(modalContext.runAtLoginFunction)) {
                contextCallback(modalContext.runAtLoginArgs);
            }
        },
        [
            callback,
            contextCallback,
            dispatch,
            forceSignUp,
            guid,
            isCompleteProfile,
            isDefaultAddressVerified,
            modalContext,
            requireCompleteProfile,
            requireLogin,
            requireUserAtLeastPartiallyRegistered,
            requireVerifiedAddress,
            runFunction,
            runFunctionArgument,
            userAtLeastPartiallyRegistered,
            userLoggedIn,
        ]
    );

    // @ts-expect-error
    return children ? React.cloneElement(React.Children.only(children), { onClick: handleClick }) : null;
};

export default ShouldLogInContainer;
